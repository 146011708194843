function initOperationsImages() {
    const sliders = document.querySelectorAll('.js-slider-operation-images');
    sliders.forEach(slider => {
        const sliderDirection = slider.classList.contains('--direction-rtl') ? 'rtl' : 'ltr';
        const cardOperation = slider.closest('.card-operation');
        const cardInSlide = cardOperation.classList.contains('card-operation--slide');

        const splide = new Splide(slider, {
            pagination: true,
            direction: sliderDirection,
            drag: cardInSlide ? false : true,
            fixedHeight: cardInSlide ? '660px' : '548px',
            autoWidth: false,
            breakpoints: {
                599: {
                    fixedHeight: cardInSlide ? '430px' : '320px',
                },
            }
        }).mount();
    });
}

(function () {
    initOperationsImages();
})();
