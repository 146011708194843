(function () {

    class BlocRelation {
        constructor(groupNode) {
            this.bloc = groupNode;

            // Init des sliders
            this.slidersArray = [];
            const sliders = this.bloc.querySelectorAll('.js-slider-relation');
            sliders.forEach((slider) => {
                this.initSlider(slider);
            });
        }

        // Init des sliders
        initSlider(slider) {
            const sliderDirection = this.bloc.classList.contains('--direction-rtl') ? 'rtl' : 'ltr';
            
            const splide = new Splide(slider, {
                gap: 20,        // gap en JS plutôt que margin en css pour gérer plus facilement l'option 'rtl'
                autoWidth: false,
                direction: sliderDirection,

                breakpoints: {
                    980: {
                        arrows: false,
                        gap: 10,
                        direction: 'ltr',       // direction toujours de gauche à droite en mobile
                    },
                }
            }).mount();
            this.slidersArray.push(splide);
        }
    }


    // Init des blocs
    const blocs = document.querySelectorAll('.bloc-relation');
    blocs.forEach(function (bloc) {
        new BlocRelation(bloc);
    });

})();
