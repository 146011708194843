function initSliderBlocAuteurCitation() {
    document.querySelectorAll('.js-slider-auteur-citation').forEach((slider) => {
        const sliderDirection = slider.classList.contains('--direction-rtl') ? 'rtl' : 'ltr';
        const splide = new Splide(slider, {
            pagination: true,
            gap: 80,        // gap en JS plutôt que margin en css pour gérer plus facilement l'option 'rtl'
            autoWidth: false,
            direction: sliderDirection,

            breakpoints: {
                980: {
                    gap: 10,        // gap en JS plutôt que margin en css pour gérer plus facilement l'option 'rtl'
                    direction: 'ltr',       // direction toujours de gauche à droite en mobile
                },
            }
        }).mount();
    });
}

(function() {
    initSliderBlocAuteurCitation();
})();
